<template>
  <div>
    <trac-loading v-if="isLoading" />
    <trac-back-button>Inventory Transfers</trac-back-button>
    <trac-modal v-if="productsModalCtrl" :cancel="false">
      <div class="mb-8">
        <trac-custom-header>
          <template slot="leading">Select</template>
          <template slot="trailing"> Product(s)</template>
        </trac-custom-header>
      </div>
      <div class="max-h-96 overflow-scroll" v-if="products">
        <div class="m-4">
          <p class="text-xs text-gray-600">
            <span class="text-red-600">*</span> Only products that are stock
            managed are displayed.
          </p>
          <input
            v-model="search"
            type="text"
            class=" border mt-4 rounded-md py-3 w-full pl-3"
          />
        </div>
        <ul class="limit">
          <li class="flex justify-between">
            <p></p>
            <p
              class="pb-2"
              :class="
                selectedProducts.length === 0 ? 'opacity-0' : 'opacity-75'
              "
            >
              Units
            </p>
          </li>
          <li
            v-for="(product, index) in filteredProducts"
            :key="index"
            class="p-1"
          >
            <div class="" v-if="product.variation">
              <div
                class="flex justify-between"
                v-for="(variant, index) in product.varieties"
                :key="index"
              >
                <trac-checkbox
                  @check="selectProduct($event, product, variant)"
                  :isChecked="variant.isSelected"
                  class="w-full px-2 hover:bg-gray-100"
                >
                  <p class="text-xs p-3 capitalize">
                    {{ variant.title }} <br />
                    <span class="text-xs text-gray-600 capitalize"
                      >{{ selectedOriginStore.name }}:
                      {{ getOriginSpecificStore(variant).stock }} units -
                      {{ selectedDestStore.name }}:
                      {{ getDestSpecificStore(variant).stock }} units</span
                    >
                  </p>
                </trac-checkbox>
                <input
                  v-if="variant.isSelected"
                  min="1"
                  :disabled="!variant.isSelected"
                  class="w-2/12 p-2"
                  :class="
                    variant.isSelected
                      ? 'border border-primaryBlue outline-none'
                      : ''
                  "
                  type="number"
                  v-model.number="variant.quantityToTransfer"
                />
              </div>
            </div>
            <div class="flex justify-between" v-else>
              <trac-checkbox
                @check="selectProduct($event, product)"
                :isChecked="product.isSelected"
                class="w-full px-2 hover:bg-gray-100"
              >
                <p class="text-xs p-3 capitalize">
                  {{ product.title }} <br />
                  <span class="text-xs text-gray-600 capitalize">
                    {{ selectedOriginStore.name }}:
                    {{ getOriginSpecificStore(product).stock }} units -
                    {{ selectedDestStore.name }}:
                    {{ getDestSpecificStore(product).stock }} units</span
                  >
                </p>
              </trac-checkbox>
              <input
                v-if="product.isSelected"
                min="1"
                :disabled="!product.isSelected"
                class="w-2/12 p-2"
                :class="
                  product.isSelected
                    ? 'border border-primaryBlue outline-none'
                    : ''
                "
                type="number"
                v-model.number="product.quantityToTransfer"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="flex justify-between mt-4">
        <trac-button @button-clicked="cancelProductsAddition" variant="outline"
          >Clear</trac-button
        >
        <trac-button
          :disabled="selectedProducts.length === 0"
          class=""
          @button-clicked="doneSelected"
          >Add Products</trac-button
        >
      </div>
    </trac-modal>

    <main class="ml-12" v-if="stores && products">
      <div class="wrapper">
        <div class="flex flex-row justify-between items-end">
          <ul class="flex-col mt-12 mb-8">
            <li><a href="#">Inventory Control</a></li>
            <li class="font-bold mt-4 text-2xl">
              Inventory Transfer: Outgoing
            </li>
          </ul>
          <div class="mb-8 flex flex-row gap-5">
            <trac-button
              :disabled="!formValid"
              @button-clicked="createTransfer(false)"
              class="uppercase"
              >Create</trac-button
            >
            <trac-button
              :disabled="!formValid"
              @button-clicked="createTransfer(true)"
              class="uppercase"
              >Create &amp; Receive</trac-button
            >
          </div>
        </div>
      </div>
      <div class=" bg-white px-8 py-8 rounded-lg  h-full  big-shadow">
        <div class="mb-8 ">
          <trac-custom-header>
            <template slot="leading">Store</template>
            <template slot="trailing"> Details</template>
          </trac-custom-header>
        </div>
        <div class="flex flex-row gap-8">
          <div class="flex-column w-4/12">
            <trac-dropdown-exteneded
              :options="stores"
              :neededProperty="'name'"
              selector="Select Source Store"
              @optionSelected="selectSourcetStore"
            ></trac-dropdown-exteneded>
          </div>
          <div class="flex-column w-4/12">
            <trac-dropdown-exteneded
              :options="filteredStores"
              :neededProperty="'name'"
              selector="Select Destination Store"
              @optionSelected="selectDestStore"
            ></trac-dropdown-exteneded>
          </div>
        </div>
        <div class="w-4/12 mt-6">
          <trac-dropdown-exteneded
            :neededProperty="''"
            :selector="selectedDateTransfer"
            :inputDate="new Date()"
            :customDropdown="true"
            @selectDate="transferDate = new Date($event).toISOString()"
          ></trac-dropdown-exteneded>
        </div>
      </div>
      <div class=" bg-white px-8 py-8 rounded-lg mt-5 h-full big-shadow">
        <div class="mb-8 ">
          <trac-custom-header>
            <template slot="leading">Items</template>
            <template slot="trailing"> Details</template>
          </trac-custom-header>
        </div>
        <ul class="w-4/6">
          <li v-for="(product, i) in selectedProducts" :key="i">
            <div class="flex justify-between">
              <p class="text-xs font-bold p-2 capitalize">
                {{ product.title }}
              </p>
              <div class="flex items-center">
                <span class="text-xs text-gray-700 font-bold"
                  >{{ product.quantityToTransfer }} Unit(s)</span
                >
                <svg
                  @click="remove(product)"
                  class="w-4 h-4 ml-4"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background:new 0 0 512 512;"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M256,0C114.508,0,0,114.497,0,256c0,141.493,114.497,256,256,256c141.492,0,256-114.497,256-256    C512,114.507,397.503,0,256,0z M256,472c-119.384,0-216-96.607-216-216c0-119.385,96.607-216,216-216    c119.384,0,216,96.607,216,216C472,375.385,375.393,472,256,472z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M343.586,315.302L284.284,256l59.302-59.302c7.81-7.81,7.811-20.473,0.001-28.284c-7.812-7.811-20.475-7.81-28.284,0    L256,227.716l-59.303-59.302c-7.809-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.81,20.474,0.001,28.284L227.716,256    l-59.302,59.302c-7.811,7.811-7.812,20.474-0.001,28.284c7.813,7.812,20.476,7.809,28.284,0L256,284.284l59.303,59.302    c7.808,7.81,20.473,7.811,28.284,0C351.398,335.775,351.397,323.112,343.586,315.302z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
            </div>
          </li>
        </ul>
        <trac-button
          :disabled="!selectedOriginStore || !selectedDestStore"
          @button-clicked="productsModalCtrl = true"
          variant="outline"
          >{{
            selectedProducts.length === 0 ? "Add Item(s)" : "Edit List"
          }}</trac-button
        >
        <br />
        <br />
        <p
          class="text-xs text-gray-600"
          v-if="!selectedOriginStore || !selectedDestStore"
        >
          <span class="text-red-600">*</span> Select source and destination
          stores to activate button.
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import {
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
} from "../../../browser-db-config/localStorage";
import { eventBus } from "./../../../main";

export default {
  name: "CreateTransfer",
  data() {
    return {
      isLoading: false,
      productsModalCtrl: false,
      products: null,
      stores: null,
      transferDate: new Date().toISOString(),
      selectedDestStore: null,
      selectedOriginStore: null,
      search: "",
    };
  },
  computed: {
    filteredStores() {
      if (this.selectedOriginStore) {
        return this.stores.filter(
          (data) => data.id !== this.selectedOriginStore.id
        );
      }
    },
    filteredProducts() {
      return this.products.filter((product) => {
        return product.title.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    selectedDateTransfer() {
      return new Date().toLocaleDateString();
    },
    selectedProducts() {
      const arr = [];
      this.products.forEach((product) => {
        if (product.variation) {
          return arr.push(...product.varieties.filter((v) => v.isSelected));
        }
        if (product.isSelected) {
          arr.push(product);
        }
      });
      return arr;
    },
    formValid() {
      return (
        this.selectedProducts.length > 0 &&
        this.selectedOriginStore &&
        this.selectedDestStore &&
        this.selectedOriginStore._id !== this.selectedDestStore._id
      );
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchAllStores();
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  methods: {
    remove(data) {
      (this.products || []).find((product) => {
        if (product.variation) {
          return (product.varieties || []).find((variety) => {
            if (variety._id === data._id) {
              variety.isSelected = false;
            }
          });
        }
        if (product._id === data._id) {
          return (product.isSelected = false);
        }
      });
    },
    selectSourcetStore(store) {
      this.selectedOriginStore = store;
    },
    selectDestStore(store) {
      this.selectedDestStore = store;
    },
    doneSelected() {
      this.productsModalCtrl = false;
    },
    cancelProductsAddition() {
      this.products = this.products.map((product) => {
        return { ...product, quantityToTransfer: 1, isSelected: false };
      });
      this.productsModalCtrl = false;
    },
    getOriginSpecificStore(prod) {
      return (
        (prod.store_stock || []).find(
          (ss) => ss.store_id === this.selectedOriginStore._id
        ) || {}
      );
    },
    getDestSpecificStore(prod) {
      return (
        (prod.store_stock || []).find(
          (ss) => ss.store_id === this.selectedDestStore._id
        ) || {}
      );
    },
    selectProduct(e, product, variant) {
      const checked = e.checked;
      this.products = this.products.map((prod, index) => {
        if (product.variation && prod._id === product._id) {
          prod.varieties.forEach((v, i) => {
            if (v._id === variant._id) {
              prod.varieties[i].isSelected = checked;
            } else {
              prod.varieties[i] = v;
            }
            return v;
          });
          return prod;
        }

        if (prod._id === product._id) {
          this.products[index].isSelected = checked;
        } else {
          this.products[index] = prod;
        }
        return prod;
      });
    },
    async createTransfer(sendReceive) {
      this.isLoading = true;
      const payload = {
        business_id: GET_USER_BUSINESS_ID(),
        destination: {
          store_id: this.selectedDestStore._id,
          store_name: this.selectedDestStore.name,
        },
        source: {
          store_id: this.selectedOriginStore._id,
          store_name: this.selectedOriginStore.name,
        },
        items: this.selectedProducts.map((product) => {
          return {
            id: product._id,
            name: product.title,
            received_quantity: product.quantityToTransfer,
            sent_quantity: product.quantityToTransfer,
            variant: product.variation,
          };
        }),
        ordered_by: GET_USER_DATA().name,
        send_and_receive: sendReceive,
      };

      await this.$store.dispatch("CREATE_TRANSFER", payload);

      const res = this.$store.getters["GET_CREATE_TRANSFER_RES"];

      this.$store.commit("SETUP_CREATE_TRANSFER", null);
      if (res.status) {
        if (sendReceive) {
          this.$store.dispatch("REFRESH_ALL_PRODUCTS", payload);
        }
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
        this.products = this.products
          .filter((prod) => prod.manage_stock)
          .map((product) => {
            if (product.variation) {
              return {
                ...product,
                varieties: product.varieties.map((v) => {
                  return { ...v, quantityToTransfer: 1, isSelected: false };
                }),
              };
            } else {
              return { ...product, quantityToTransfer: 1, isSelected: false };
            }
          });
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data;
        this.selectedStore = this.stores[0];
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
  },
};
</script>

<style scoped>
.limit {
  max-height: 25rem;
  overflow-y: auto;
}
</style>
